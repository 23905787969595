//// Dataviz
@import "~@progress/kendo-theme-default/scss/dataviz";
@import "~@progress/kendo-theme-default/scss/map/_index.scss";
@import "~@progress/kendo-theme-default/scss/dataviz/_variables.scss";

// Excel Table
@import "~@progress/kendo-theme-default/scss/grid";
@import "~@progress/kendo-theme-default/scss/gantt/_variables.scss";
@import "~@progress/kendo-theme-default/scss/gantt/_layout.scss";

td {
  text-align: left !important;
}

td + td {
  text-align: right !important;
}

.matrix-table {
  td + td {
    text-align: center !important;
  }

  .ant-radio-wrapper {
    margin-right: 0;
  }
}

tr {
  text-align: center !important;
}


tr.selectable-row {
  &:hover {
    cursor: pointer;
  }
}

.matrix-cell {
  text-align: center !important;
}



.matrix-table-analysis {
  // table row hover effect
  .k-grid tr:hover, .k-grid tr.k-alt:hover {
    background: transparent !important;
  }

  .k-grid tr.k-alt {
    background: transparent !important;
  }

  td {
    @apply border-solid border-b-2 border-gray-100
  }
}
