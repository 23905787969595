@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@border-radius-base: 4px;
@screen-xxl: 1850px;
@screen-xl: 1400px;


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.ant-input {
  font-weight: inherit;
}

.ant-tabs-top {
  .ant-tabs-tab-btn {
    padding-left: 16px;
    padding-right: 16px;
  }
}


#root {
  height: 100%;
  width: 100%;
}

.ant-divider-horizontal {
  margin: 12px 0;
}


.ant-form-vertical .ant-form-item-label {
  padding: 0 0 4px;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 0
}


.slider-answer {
  width: 100%;

  .ant-slider-rail,
  .ant-slider-step,
  .ant-slider-track {
    height: 10px;
  }

  .ant-slider-handle {
    height: 20px;
    width: 20px;
  }

  .ant-slider-dot {
    display: none;
  }

  .ant-slider-mark {
    margin-top: 10px;
  }
}


// rating
.rating-answer {
  .anticon-star {
    font-size: 50px;
  }
}

